<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-link class="brand-logo">
      <img src="/img/logo-dialog.405b5032.svg" alt="">
    </b-link>
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">

        <!-- form -->
        <validation-observer
            ref="loginForm"
          >
            <b-form
              class="offset-md-3 col-md-6 text-center auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Ssid"
                  vid="ssid"
                >
                  <b-form-input
                    class="text-center"
                    id="ssid"
                    :state="errors.length > 0 ? false:null"
                    name="ssid"
                    placeholder="SSID"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      class="form-control-merge text-center"
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      :type="passwordFieldType"
                      name="login-password"
                      :placeholder="$t('Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                class="btn-login"
              >
                {{$t("Login")}}
              </b-button>

            </b-form>
          </validation-observer>

        <b-card-text class="register-login-btn text-center mt-2">
          <b-button
            type="submit"
            class="btn-reboot"
            variant="none"
          >
            Reboot
          </b-button>
        </b-card-text>

        <b-card-text class="register-login-btn text-center mt-2">
          <b-link :to="{name:'auth-register'}">
            <span>{{$t("Register")}}</span>
          </b-link>
        </b-card-text>

        <b-card-text class="forgot-btn text-center mt-2">
          <b-link :to="{name:'auth-forgot-password'}">
            <span>{{$t("Forgot Password?")}}</span>
          </b-link>
        </b-card-text>
      </b-card>
    </div>
    <a class="mercatus-logo" href="https://cooling.mercatus.pt/pt/home" target="_blank">
      <img src="@/assets/images/logo/logo-mercatus-white.png" alt="">
    </a>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import CostLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    CostLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              const { userData } = response.data
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `${this.$t("Welcome")} ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `${this.$t("You have successfully logged in as")} ${userData.role}. ${this.$t("Now you can start to explore!")}`,
                    },
                  })
                })
            })
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
